var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doc)?_c('router-link',{staticClass:"article",class:{
    flash: _vm.doc.urgency === 1,
    alerte: _vm.doc.product !== 'photo' && _vm.doc.urgency === 2,
    urgent: _vm.doc.product !== 'photo' && _vm.doc.urgency === 3,
    photo: _vm.doc.product === 'photo',
    multimedia: _vm.doc.product === 'multimedia',
    news: _vm.doc.product === 'news',
    video: ['afptv', 'SIDTV', 'sidtv', 'parismode', 'afptvweb', 'afptv1st', 'videographie'].includes(_vm.doc.product),
    canceled: _vm.doc.status === 'Canceled'
  },attrs:{"lang":_vm.doc.lang,"dir":_vm.doc.lang === 'ar' ? 'rtl' : 'ltr',"to":{ name: 'document', params: { indexCol: _vm.indexCol, docId: _vm.docId } }}},[(_vm.doc.medias.length > 0 && _vm.doc.medias[0].sizes.some(function (size) { return size.role === 'Preview' || size.role === 'HighDef'; }))?_c('div',{staticClass:"img-container",style:({
      backgroundImage: ("url(" + (_vm.selectPreview(_vm.doc.medias[0].sizes).href) + ")"),
      backgroundPosition: _vm.doc.medias[0].faceYOffsetPercent ? ("0px " + (_vm.doc.medias[0].faceYOffsetPercent * 100) + "%"): null
    })},[(['afptv', 'SIDTV', 'sidtv', 'parismode', 'afptvweb', 'afptv1st', 'videographie'].includes(_vm.doc.product))?_c('i',{staticClass:"UI-icon UI-play-video"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"cols"},[(_vm.doc.embargoed && _vm.doc.embargoed > new Date())?_c('p',{key:("date-" + _vm.locale),staticClass:"date embargo"},[_vm._v(" Embargo : "+_vm._s(_vm._f("fromNow")(_vm.doc.embargoed,_vm.$root.now))+" ")]):_c('p',{key:("date-" + _vm.locale),staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("fromNow")(_vm.doc.published,_vm.$root.now))+" ")]),(_vm.doc.product === 'photo' && _vm.doc.urgency === 1)?_c('p',{staticClass:"genre"},[_vm._v(" Topshot ")]):(_vm.doc.genre)?_c('p',{staticClass:"genre"},[_c('span',[_vm._v(" "+_vm._s(_vm.doc.genre)+" ")])]):_vm._e()]),(_vm.doc.product !== 'photo')?_c('h2',[_vm._v(" "+_vm._s(_vm.doc.headline)+" ")]):_vm._e(),(['news', 'multimedia'].includes(_vm.doc.product) && _vm.doc.urgency > 2 && _vm.doc.news && _vm.doc.news[0] && _vm.doc.status === 'Usable')?_c('p',{staticClass:"lead"},[_vm._v(" "+_vm._s(_vm.doc.news[0].substr(0, 100) + '...')+" ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }